import styled from '@emotion/styled';
import { Box, Grid, Container, Typography } from '@mui/material';
import { LaerAILogo } from 'features/pages/landing/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RequestDemoDialog } from '../../../../components/request-demo-dlg';
import { menus } from '../menus';
import { FooterMenu } from './footer-menu';


const Text = styled(Typography)(({ fontSize }) => ({
  color: '#6085B0',
  fontSize,
  lineHeight: 1.75,
  marginTop: 8,
  marginBottom: 8,
  fontFamily: `'Poppins', sans-serif`
}));

const Footer = () => {
  const [modal, setModal] = useState(false);
  const year = new Date().getFullYear()
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  return (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%', bgcolor: '#180636', color: 'white', px: { xs: 4, sm: 6, md: 8 }, py: { xs: 4 }, maxWidth: { xs: '100%', xl: '1340px' }, mx: 'auto', mb: 5, mt: [5, null, 8, 12], borderRadius: '24px' }}>
        <Grid container sx={{ pb: 6, borderBottom: '1px solid #171449' }} >
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', minHeight: 120 }} >
            <Box sx={{ my: 2, maxWidth: '240px' }}>
              <Link to="/">
                <LaerAILogo color="white" />
              </Link>
              <Text fontSize={16}>
                42 Broadway, 12th Floor #211 New York, NY 10004
              </Text>
            </Box>
          </Grid>

          <Grid item xs={0} md={1} />

          <Grid item xs={12} md={7} container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4, width: '100%', flexWrap: 'wrap', marginLeft: 'auto', maxWidth: { md: '600px' } }}>
              {menus.map((menu, index) => (
                <FooterMenu key={index} {...menu} onClick={openModal} />
              ))}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', pt: 2, flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Text fontSize={16}>@ Laer AI {year}. All Rights Reserved.</Text>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Link to="https://www.linkedin.com/company/laer-ai" target='_blank'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M15.2004 9.19995C16.4734 9.19995 17.6943 9.70566 18.5945 10.6058C19.4947 11.506 20.0004 12.7269 20.0004 14V19.6H16.8004V14C16.8004 13.5756 16.6318 13.1686 16.3318 12.8686C16.0317 12.5685 15.6247 12.4 15.2004 12.4C14.776 12.4 14.3691 12.5685 14.069 12.8686C13.769 13.1686 13.6004 13.5756 13.6004 14V19.6H10.4004V14C10.4004 12.7269 10.9061 11.506 11.8063 10.6058C12.7065 9.70566 13.9274 9.19995 15.2004 9.19995Z" fill="#FEFEFE" />
                <path d="M7.2 10H4V19.6H7.2V10Z" fill="#FEFEFE" />
                <path d="M5.6 7.5999C6.48366 7.5999 7.2 6.88356 7.2 5.9999C7.2 5.11625 6.48366 4.3999 5.6 4.3999C4.71634 4.3999 4 5.11625 4 5.9999C4 6.88356 4.71634 7.5999 5.6 7.5999Z" fill="#FEFEFE"/>
              </svg>
            </Link>
          </Box>
        </Box>

        <RequestDemoDialog title="Request Resources" open={modal} onClose={closeModal} />
      </Box>
    </Container>
  );
}

export default Footer;
